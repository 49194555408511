import { isNotEmpty } from '@harmonizeai/poseidon-shared';
import clsx from 'clsx';
import React, { type CSSProperties } from 'react';
import { CDNImage } from '../CDNImage';
import styles from './IconWithPlaceholder.module.scss';

interface CommonProps {
  size: number;
  shape?: 'circle' | 'roundedSquare';
  className?: string;
}

function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash) % 6;
}

interface IconWithPlaceholderProps extends CommonProps {
  urls?: (string | undefined)[];
  alt: string;
  placeholder?: JSX.Element | string | undefined;
  style?: React.CSSProperties;
}

export const IconWithPlaceholder = React.forwardRef<HTMLDivElement, IconWithPlaceholderProps>(
  ({ urls, size, alt, shape, placeholder, style, className }, ref) => {
    const [srcIndex, setSrcIndex] = React.useState(0);

    const colorIndex =
      placeholder != null && typeof placeholder === 'string' ? hashCode(placeholder) : undefined;

    const colors = [
      'linear-gradient(45deg, #42a11f, #2dca1f)',
      'linear-gradient(45deg, #73173f, #ca1f68)',
      'linear-gradient(45deg, #99aa38, #3e761a)',
      'linear-gradient(45deg, #25145e, #3910bc)',
      'linear-gradient(45deg, #621e76, #9f1fca)',
      'linear-gradient(45deg, #a92e2e, #ec2727)',
    ];

    const src = urls?.filter(isNotEmpty)?.[srcIndex];
    const _style = {
      ...style,
      '--icon-size': `${size}px`,
      '--font-weight': size < 32 ? 600 : 400,
      '--color': colorIndex && src == null ? 'white' : undefined,
      '--background': colorIndex && src == null ? colors[colorIndex] : undefined,
    } as CSSProperties;
    return (
      <div
        ref={ref}
        className={clsx(styles.icon, className, shape ? styles?.[shape] : undefined)}
        style={_style}
      >
        {src && (
          <CDNImage
            width={size}
            height={size}
            alt={alt}
            src={src}
            onError={() => setSrcIndex((i) => i + 1)}
          />
        )}
        {!src && placeholder}
      </div>
    );
  },
);

IconWithPlaceholder.displayName = 'IconWithPlaceholder';
