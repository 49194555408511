'use client';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import React from 'react';
import { env } from '~/env.mjs';

interface IProps extends ImageProps {
  fallbackSrc?: string;
  alt: string;
}

const imgProxyLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  let fullPathSrc = src;

  if (src.startsWith('/')) {
    const backendUrl = env.NEXT_PUBLIC_BACKEND_URL;
    if (backendUrl.startsWith('http://localhost')) {
      return src;
    }

    fullPathSrc = backendUrl + src;
  }

  const options =
    `/size:${width}:::` + (quality ? `/quality:${quality}` : '') + `/plain/${fullPathSrc}`;
  const result = env.NEXT_PUBLIC_IMAGE_RESIZING_SERVER_URL + '/insecure' + options;
  return result;
};
export const CDNImage = ({ src, alt, fallbackSrc, width, ...rest }: IProps) => {
  const [imgSrc, setImgSrc] = React.useState(src);

  const handleFallbackSrc = React.useCallback(() => {
    if (fallbackSrc) {
      setImgSrc(fallbackSrc);
    }
  }, [fallbackSrc]);

  if (fallbackSrc == null) {
    return <Image {...rest} width={width} loader={imgProxyLoader} unoptimized src={imgSrc} alt={alt} />;
  }

  return (
    <Image
      {...rest}
      width={width}
      loader={imgSrc === fallbackSrc ? undefined : imgProxyLoader}
      unoptimized
      src={imgSrc}
      alt={alt}
      onError={handleFallbackSrc}
    />
  );
};
