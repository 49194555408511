'use client';
import React, { type SVGAttributes } from 'react';
import VimeoPopup from '../VimeoPopup/VimeoPopup';
import styles from './MarketLeadersSection.module.scss';
import { CDNImage } from '../CDNImage';

interface PersonProps {
  src: string;
  vimeoId?: string;
  name: string;
  line1: string;
  line2?: string;
}

export const Person: React.FC<PersonProps> = ({ src, vimeoId }) => {
  const [showPopup, setShowPopup] = React.useState(false);
  return (
    <>
      {/* <div className={styles.person2}>
        <div className={styles.background}>{vimeoId && <PlayCircle className={styles.play} />}</div>
        <div className={styles.text}>
          <div className={styles.name}>{name}</div>
          <div className={styles.line}>{line1}</div>
          <div className={styles.line}>{line2}</div>
        </div>
      </div> */}
      <div className={styles.person} onClick={() => setShowPopup(true)} role="button">
        <CDNImage width={110} height={153} src={src} alt="person" />
      </div>
      {showPopup && vimeoId && <VimeoPopup vimeoId={vimeoId} setShowPopup={setShowPopup} />}
    </>
  );
};

const fillProps = {
  fill: 'var(--fill, currentColor)',
  fillOpacity: 'var(--fill-opacity, 0.12)',
} as const;

const svgProps: SVGAttributes<SVGElement> = {
  viewBox: '0 0 24 24',
  width: '24px',
  height: '24px',
} as const;

export const PlayCircle: React.FC<React.HTMLProps<SVGElement>> = ({ className }) => {
  return (
    <svg className={className} {...svgProps}>
      <path
        clip-rule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM9.59974 8.11617C9.5 8.2494 9.5 8.48804 9.5 8.96533V15.0347C9.5 15.5119 9.5 15.7506 9.59974 15.8838C9.68666 15.9999 9.81971 16.0726 9.96438 16.0829C10.1304 16.0948 10.3311 15.9657 10.7326 15.7076L10.7326 15.7076L15.4532 12.6729C15.8016 12.449 15.9758 12.337 16.0359 12.1946C16.0885 12.0702 16.0885 11.9298 16.0359 11.8054C15.9758 11.663 15.8016 11.551 15.4532 11.3271L10.7326 8.29239C10.3311 8.03429 10.1304 7.90524 9.96438 7.9171C9.81971 7.92743 9.68666 8.00007 9.59974 8.11617Z"
        {...fillProps}
      />
    </svg>
  );
};
