'use client'
import React from 'react';
import { LinkButton } from '~/app/_components/Button/Button';
import styles from './BottomCTASection.module.scss';
import clsx from 'clsx';

export const BottomCTASection: React.FC = () => {
  return (
    <section className={clsx(styles.section, 'fullWidth')}>
      <div className={styles.content}>
        <h2>Join elite networkers, sales prospectors and closers the world over using Poseidon</h2>
        <h6>Get started in minutes</h6>

        <LinkButton type="primary" className={styles.button} href="/signup">
          Sign Up
        </LinkButton>
      </div>
    </section>
  );
};
